<template>
  <div class="d-flex flex-row">
    <v-select
      class="field"
      solo
      hide-details
      :items="statuses"
      :label="$t('t.Status')"
      item-text="name"
      item-value="id"
      v-model="computedCloseTopic"
      :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
    />
    <v-spacer />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    computedCloseTopic: {
      get () { return this.dataCloseTopic },
      set (v) {
        this.dataCloseTopic = v
        this.dataDocumentEmit()
      }
    },
    statuses () {
      const statuses = []
      statuses.push({
        id: 0,
        name: this.$t('t.OpenTopic')
      })
      statuses.push({
        id: 1,
        name: this.$t('t.CloseTopic')
      })
      return statuses
    }
  },
  data () {
    return {
      dataCloseTopic: 1,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = { type: 'update-topic-status', toClose: this.dataCloseTopic === 1 }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean

  },
  watch: {
    document: {
      handler (v) {
        this.dataCloseTopic = v?.toClose ? 1 : 0
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
